<template>
  <form class="login-form" @submit.prevent="onSubmit">
    <dx-form :form-data="formData" :disabled="loading">
      <dx-item
        data-field="email"
        editor-type="dxTextBox"
        :editor-options="{ stylingMode: 'filled', placeholder: 'Email', mode: 'email' }"
      >
        <dx-required-rule message="Email is required" />
        <dx-email-rule message="Email is invalid" />
        <dx-label :visible="false" />
      </dx-item>
      <dx-item
        data-field='password'
        editor-type='dxTextBox'
        :editor-options="{ stylingMode: 'filled', placeholder: 'Password', mode: 'password' }"
      >
        <dx-required-rule message="Password is required" />
        <dx-label :visible="false" />
      </dx-item>
       <dx-item
        data-field="rememberMe"
        editor-type="dxCheckBox"
        :editor-options="{ text: 'Lembrar de mim', elementAttr: { class: 'form-text' } }"
      >
        <dx-label :visible="false" />
      </dx-item> 
      <dx-button-item>
        <dx-button-options
          width="100%"
          type="default"
          template="signInTemplate"
          :use-submit-behavior="true"
          :on-click="onSubmit"
        >
        </dx-button-options>
      </dx-button-item>
      <!-- <dx-item>
        <template #default>
          <div class="link">
            <router-link to="/reset-password">Forgot password?</router-link>
          </div>
        </template>
      </dx-item> -->
      <!-- <dx-button-item>
        <dx-button-options
          text="Create an account"
          width="100%"
          :on-click="onCreateAccountClick"
        />
      </dx-button-item> -->
      <template #signInTemplate>
        <div>
          <span class="dx-button-text">
            <dx-load-indicator v-if="loading" width="24px" height="24px" :visible="true" />
            <span v-if="!loading">login</span>
          </span>
        </div>
      </template>
    </dx-form>
  </form>
</template>

<script>
import DxLoadIndicator from "devextreme-vue/load-indicator";
import DxForm, {
  DxItem,
  DxEmailRule,
  DxRequiredRule,
  DxLabel,
  DxButtonItem,
  DxButtonOptions
} from "devextreme-vue/form";
import notify from 'devextreme/ui/notify';

//import auth from "../auth";


import { reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {login} from '../services/auth.service';

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();

    let userName = localStorage.getItem('rememberMeUser') ;
    let LrememberMe = localStorage.getItem('rememberMe') === 'true' ? true : false;
    const formData = reactive({
      email:userName,
      password:"",
      rememberMe:  LrememberMe
    });
    const loading = ref(false);
      
    function onCreateAccountClick() {
      router.push("/create-account");
    }

    async function  onSubmit() {
      const { email, password, rememberMe } = formData;
      
      loading.value = true;
      const result = await login(email , password) ;
      console.log(result.Status)
      if (result.Status == 200) {
          loading.value = false;
          localStorage.setItem('rememberMe', rememberMe === true ? 'true': 'false');
          localStorage.setItem('rememberMeUser',rememberMe === true ? email: '');
          router.push(route.query.redirect || "/home");
      } else {
          loading.value = false;
          notify(result.Message, "error", 2000);
        
      }
    }

    return {
      formData,
      loading,
      onCreateAccountClick,
      onSubmit
    };
  },
  components: {
    DxLoadIndicator,
    DxForm,
    DxEmailRule,
    DxRequiredRule,
    DxItem,
    DxLabel,
    DxButtonItem,
    DxButtonOptions
  }
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

.login-form {
  .link {
    text-align: center;
    font-size: 16px;
    font-style: normal;

    a {
      text-decoration: none;
    }
  }

  .form-text {
    margin: 10px 0;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  }
}
</style>
